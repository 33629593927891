import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';
import styles from './Footer.module.scss';
import t from '../../utils/translate';
import LangSwitch from '../LangSwitch/LangSwitch';
import Vector from '../Vector/Vector';
import FooterInfoBlock from './FooterInfoBlock/FooterInfoBlock';
import ContactInfo from '../ContactInfo/ContactInfo';
import Logo from '../Logo/Logo';
import LocaleLink from '../LocaleLink/LocaleLink';

const Footer = ({ dark, location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          sanityFooter {
            ...FooterQuery
          }
        }
      `}
      render={({ sanityFooter: { footerInfo, contactInfo } }) => (
        <footer
          className={classNames(
            styles.footerOuter,
            dark ? styles.dark : undefined
          )}
        >
          <div className={styles.footerInner}>
            <div className={styles.footerInfoBlocks}>
              {contactInfo.map((content, index) => {
                return <ContactInfo key={index} dark={dark} {...content} />;
              })}
              <div className={styles.footerInfo}>
                {footerInfo.map((content, index) => {
                  return (
                    <FooterInfoBlock
                      key={index}
                      dark={dark}
                      location={location}
                      {...content}
                    />
                  );
                })}
              </div>
            </div>

            <ul className={styles.footerNav}>
              <li>
                <LocaleLink to="/besok">{t('navVisit')}</LocaleLink>
              </li>
              <li>
                <LocaleLink to="/opplevelser">{t('navExperiences')}</LocaleLink>
              </li>
              <li>
                <LocaleLink to="/om-oss">{t('navAbout')}</LocaleLink>
              </li>
            </ul>

            <div className={styles.footerLinkSection}>
              <div className={styles.footerLogo}>
                <Logo fill={dark ? '#fff' : '#000'} />
                <a
                  href="https://www.ntnu.no/museum"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.ntnuLink}
                >
                  <Vector
                    type={'ntnu'}
                    colour={dark ? '#fff' : '#000'}
                    className={styles.ntnu}
                  />

                  <Vector
                    type={'ntnu-alt'}
                    colour={dark ? '#fff' : '#000'}
                    className={styles.ntnuAlt}
                  />
                </a>
              </div>

              <div className={styles.footerLinks}>
                <LocaleLink className={styles.privacyLink} to="/personvern">
                  Personvernerklæring
                </LocaleLink>
                <LangSwitch inverted={dark ? false : true} />
                <button
                  title={t('toTop')}
                  className={styles.scrollToTopButton}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  <Vector type={'arrow-up'} colour={dark ? '#fff' : '#000'} />
                </button>
              </div>
            </div>
          </div>
        </footer>
      )}
    />
  );
};

export default Footer;
